<template>
  <div
    class="invalidCoupon"
    :class="{ noneCoupon: couponNotAvailableList.length === 0 }"
  >
    <header ref="header">无效券</header>
    <div class="list" v-if="couponNotAvailableList.length">
      <div
        class="list-item"
        :key="item.id"
        v-for="(item, index) in couponNotAvailableList.filter(
          (item) => item.type === 0 || item.type === 1
        )"
      >
        <div class="partTop">
          <div class="coupon-name">
            <div>{{ item.name }}</div>
            <div>{{ item.description }}</div>
          </div>
          <div class="coupon-limit">
            <div class="coupon-amount">
              <div class="notDiscount" v-if="item.type === 0">
                <span>¥</span>
                <span>{{ item.discountPrice.toFixed(1).split('.')[0] }}</span>
                <span>.{{ item.discountPrice.toFixed(1).split('.')[1] }}</span>
              </div>
              <div class="discount" v-else>
                {{ item.discountPrice * 10 }}
                <span>折</span>
              </div>
              <div class="description" v-if="item.overPrice > 0">
                满
                <span>{{ item.overPrice }}</span>
                可用
              </div>
              <div class="description" v-else>无门槛</div>
            </div>
          </div>
        </div>
        <div class="partBottom">
          <div class="expire">
            <span>有效期：</span>
            <span>{{ item.beginDate }}～{{ item.endDate }}</span>
          </div>
          <div class="peroid" v-if="item.timePeriod">
            <span>使用时段：</span>
            <span>{{ item.timePeriod }}</span>
          </div>
          <div class="remark" v-if="item.remark" @click="openCoupon(index)">
            <div>
              <span>使用说明</span>
              <img
                class="toggle"
                v-if="item.open"
                src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/put.png"
              />
              <img
                class="toggle"
                v-else
                src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/pull.png"
              />
            </div>
            <div class="remark-detail">
              <span v-if="item.open">{{ item.remark }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="noneCoupon" v-else>
      <img src="../assets/imgs/noneCoupon.png" alt="" />
      <span>暂无优惠券</span>
    </div>
    <div v-if="couponNotAvailableList.length" class="nomore">没有更多了</div>
  </div>
</template>

<script>
import { getUserCouponList } from '@/api/api';
export default {
  mounted() {
    this.listener = this.scrollTitle();
    this.getCouponList();
    this.utils.setTitle('');
  },

  listener: null,
  data() {
    return {
      title: '',
      couponNotAvailableList: [],
    };
  },
  watch: {
    title(val) {
      this.utils.setTitle(val);
    },
  },
  computed: {
    selectable() {
      return false;
    },
    noneCoupon() {
      return (
        this.couponAvailableList.length === 0 &&
        this.couponNotAvailableList.length === 0
      );
    },
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.listener);
  },
  methods: {
    openCoupon(index) {
      let list = this.couponNotAvailableList;
      list[index].open = !list[index].open;
      this.couponNotAvailableList = list;
    },
    scrollTitle() {
      const scrollElement = document.querySelector('#app');
      const { height } = this.$refs.header.getBoundingClientRect();
      const callback = () => {
        if (scrollElement.scrollTop < height) {
          this.title = '';
        } else {
          this.title = '无效券';
        }
      };
      scrollElement.addEventListener('scroll', callback, {
        passive: true,
      });
      return callback;
    },
    getCouponList() {
      getUserCouponList({
        orderCode: '',
        skuCodeSkuCount: '',
      }).then((data) => {
        if (data) {
          this.couponAvailableList = data.couponAvailableList.map((d) =>
            Object.assign(d, { open: false })
          );
          this.couponNotAvailableList = data.couponNotAvailableList.map((d) =>
            Object.assign(d, { open: false })
          );
          // const couponNotAvailableList = [
          //   {
          //     overPrice: 0,
          //     endDate: "2022-12-06",
          //     discountPrice: 10,
          //     weekPeriod: "",
          //     description: "限北京滨水公园网格使用",
          //     index: 4,
          //     remark: "不在指定网格",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "不在指定网格",
          //     timePeriod: "",
          //     topic: "不在指定网格",
          //     id: 156747,
          //     bargainMsg: "本券可与特价商品叠加使用",
          //   },
          //   {
          //     overPrice: 0,
          //     endDate: "2022-12-06",
          //     discountPrice: 10,
          //     weekPeriod: "",
          //     description: "限北京滨水公园网格使用",
          //     index: 5,
          //     remark: "不在指定网格",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "不在指定网格",
          //     timePeriod: "",
          //     topic: "不在指定网格",
          //     id: 156748,
          //     bargainMsg: "本券可与特价商品叠加使用",
          //   },
          //   {
          //     overPrice: 0.01,
          //     endDate: "2022-12-06",
          //     discountPrice: 4,
          //     weekPeriod: "",
          //     description: "不可与特价商品叠加使用",
          //     index: 8,
          //     remark: "不可与特价商品叠加使用",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "不可与特价商品叠加使用",
          //     timePeriod: "",
          //     topic: "不可与特价商品叠加使用",
          //     id: 156569,
          //     bargainMsg: "",
          //   },
          //   {
          //     overPrice: 0.01,
          //     endDate: "2022-12-06",
          //     discountPrice: 4,
          //     weekPeriod: "",
          //     description: "不可与特价商品叠加使用",
          //     index: 9,
          //     remark: "不可与特价商品叠加使用",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "不可与特价商品叠加使用",
          //     timePeriod: "",
          //     topic: "不可与特价商品叠加使用",
          //     id: 156570,
          //     bargainMsg: "",
          //   },
          //   {
          //     overPrice: 0.01,
          //     endDate: "2022-12-06",
          //     discountPrice: 3,
          //     weekPeriod: "",
          //     description: "不限星期使用，限23:00-23:59使用",
          //     index: 10,
          //     remark: "不在指定时间",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "不在指定时间",
          //     timePeriod: "23:00~23:59",
          //     topic: "不在指定时间",
          //     id: 156565,
          //     bargainMsg: "本券可与特价商品叠加使用",
          //   },
          //   {
          //     overPrice: 0.01,
          //     endDate: "2022-12-06",
          //     discountPrice: 3,
          //     weekPeriod: "",
          //     description: "不限星期使用，限23:00-23:59使用",
          //     index: 11,
          //     remark: "不在指定时间",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "不在指定时间",
          //     timePeriod: "23:00~23:59",
          //     topic: "不在指定时间",
          //     id: 156566,
          //     bargainMsg: "本券可与特价商品叠加使用",
          //   },
          //   {
          //     overPrice: 0.01,
          //     endDate: "2022-02-22",
          //     discountPrice: 1,
          //     weekPeriod: "",
          //     description: "1、西西里达旺拉拉券",
          //     index: 1,
          //     remark: "1、西西里达旺拉拉券",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2022-02-16",
          //     endHour: "2022-02-22 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "西西里达旺拉拉券1",
          //     timePeriod: "",
          //     topic: "西西里达旺拉拉券",
          //     id: 157778,
          //     bargainMsg: "",
          //   },
          //   {
          //     overPrice: 0.01,
          //     endDate: "2022-02-23",
          //     discountPrice: 1,
          //     weekPeriod: "",
          //     description: "1、西西里达旺拉拉券",
          //     index: 0,
          //     remark: "1、西西里达旺拉拉券",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2022-02-17",
          //     endHour: "2022-02-23 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "西西里达旺拉拉券1",
          //     timePeriod: "",
          //     topic: "西西里达旺拉拉券",
          //     id: 157821,
          //     bargainMsg: "",
          //   },
          //   {
          //     overPrice: 1000000,
          //     endDate: "2022-12-06",
          //     discountPrice: 1,
          //     weekPeriod: "",
          //     description: "未达到使用门槛",
          //     index: 6,
          //     remark: "未达到使用门槛",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "未达到使用门槛",
          //     timePeriod: "",
          //     topic: "未达到使用门槛",
          //     id: 156567,
          //     bargainMsg: "本券可与特价商品叠加使用",
          //   },
          //   {
          //     overPrice: 1000000,
          //     endDate: "2022-12-06",
          //     discountPrice: 1,
          //     weekPeriod: "",
          //     description: "未达到使用门槛",
          //     index: 7,
          //     remark: "未达到使用门槛",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "未达到使用门槛",
          //     timePeriod: "",
          //     topic: "未达到使用门槛",
          //     id: 156568,
          //     bargainMsg: "本券可与特价商品叠加使用",
          //   },
          //   {
          //     overPrice: 0,
          //     endDate: "2022-12-06",
          //     discountPrice: 1,
          //     weekPeriod: "",
          //     description: "1、西西里达旺拉拉券1111",
          //     index: 12,
          //     remark: "1、西西里达旺拉拉券1111",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-11-30",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "西西里达旺拉拉券111",
          //     timePeriod: "",
          //     topic: "西西里达旺拉拉券复制",
          //     id: 156236,
          //     bargainMsg: "",
          //   },
          //   {
          //     overPrice: 0,
          //     endDate: "2022-12-06",
          //     discountPrice: 1,
          //     weekPeriod: "",
          //     description: "1、西西里达旺拉拉券1111",
          //     index: 13,
          //     remark: "1、西西里达旺拉拉券1111",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-11-30",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "西西里达旺拉拉券111",
          //     timePeriod: "",
          //     topic: "西西里达旺拉拉券复制",
          //     id: 156237,
          //     bargainMsg: "",
          //   },
          //   {
          //     overPrice: 0,
          //     endDate: "2022-12-06",
          //     discountPrice: 1,
          //     weekPeriod: "",
          //     description: "1、西西里达旺拉拉券1111",
          //     index: 14,
          //     remark: "1、西西里达旺拉拉券1111",
          //     type: 0,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-11-30",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "西西里达旺拉拉券111",
          //     timePeriod: "",
          //     topic: "西西里达旺拉拉券复制",
          //     id: 156238,
          //     bargainMsg: "",
          //   },
          //   {
          //     overPrice: 0.01,
          //     endDate: "2022-12-06",
          //     discountPrice: 0.5,
          //     weekPeriod: "",
          //     description: "限C74车辆使用",
          //     index: 2,
          //     remark: "不在指定车辆",
          //     type: 1,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "不在指定车辆",
          //     timePeriod: "",
          //     topic: "不在指定车辆",
          //     id: 156745,
          //     bargainMsg: "本券可与特价商品叠加使用",
          //   },
          //   {
          //     overPrice: 0.01,
          //     endDate: "2022-12-06",
          //     discountPrice: 0.5,
          //     weekPeriod: "",
          //     description: "限C74车辆使用",
          //     index: 3,
          //     remark: "不在指定车辆",
          //     type: 1,
          //     url: "",
          //     maxDiscountPrice: -1,
          //     beginDate: "2021-12-21",
          //     endHour: "2022-12-06 23",
          //     overdue: -1,
          //     overPriceEnable: true,
          //     name: "不在指定车辆",
          //     timePeriod: "",
          //     topic: "不在指定车辆",
          //     id: 156746,
          //     bargainMsg: "本券可与特价商品叠加使用",
          //   },
          // ];
          // this.couponNotAvailableList = couponNotAvailableList.map((d) =>
          //   Object.assign(d, { open: false })
          // );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.invalidCoupon {
  min-height: 100%;
  background: #f5f5fa;
  @include background_color('coupon_bg');
  &.noneCoupon {
    height: 100vh;
    background: white;
    @include background_color('coupon_bg');
  }
  header {
    height: 1.16rem;
    font-size: 0.48rem;
    color: #181818;
    line-height: 1.16rem;
    padding-left: 0.48rem;
    background: white;
    font-weight: bold;
    @include font_color('title_color');
    @include background_color('title_bg');
    top: 0;
    left: 0;
  }
  .list {
    &-item {
      width: 6.9rem;
      min-height: 2.76rem;
      background: white;
      @include background_color('invalid_coupon_card_bg');
      position: relative;
      margin: 0.3rem auto 0;
      padding: 0.3rem 0.32rem 0.3rem 0.4rem;
      box-sizing: border-box;
      border-radius: 0.16rem;
      &::after {
        width: 0.28rem;
        height: 0.28rem;
        display: block;
        content: '';
        position: absolute;
        right: -0.14rem;
        top: 1.36rem;
        background: #f5f5f5;
        @include background_color('coupon_bg');
        z-index: 2;
        border-radius: 50%;
      }
      &::before {
        width: 0.28rem;
        height: 0.28rem;
        display: block;
        content: '';
        position: absolute;
        left: -0.14rem;
        top: 1.36rem;
        background: #f5f5f5;
        @include background_color('coupon_bg');
        z-index: 2;
        border-radius: 50%;
      }
      .partTop {
        border-bottom: 0.02rem dashed #eaeaea;
        @include border_color('coupon_card_border');
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.2rem;
        .coupon-name {
          div {
            &:nth-child(1) {
              font-size: 0.32rem;
              color: #b3b3b3;
              font-weight: bold;
              margin-bottom: 0.12rem;
              @include font_color('invalid_coupon_card_title');
            }
            &:nth-child(2) {
              font-size: 0.24rem;
              color: #c2c2c2;
              @include font_color('invalid_coupon_card_text');
            }
          }
        }
        .coupon-limit {
          display: flex;
          align-items: center;
          justify-content: center;
          .coupon-amount {
            text-align: right;
            color: #ffbeb1;
            @include font_color('invalid_coupon_card_amount');
            .notDiscount {
              font-weight: bold;
              span {
                &:nth-child(1) {
                  font-size: 0.28rem;
                }
                &:nth-child(2) {
                  font-size: 0.6rem;
                }
                &:nth-child(3) {
                  font-size: 0.28rem;
                }
              }
            }
            .discount {
              font-weight: bold;
              font-size: 0.6rem;
              span {
                font-size: 0.28rem;
              }
            }
            .description {
              margin-top: 0.04rem;
              font-size: 0.24rem;
              span {
                font-weight: bold;
              }
            }
          }
        }
      }
      .partBottom {
        font-size: 0.24rem;
        padding: 0.3rem 0 0;
        > div {
          color: #d6d6d6;
          @include font_color('invalid_coupon_card_text');
          line-height: 0.36rem;
        }
        .remark {
          img {
            margin-left: 0.12rem;
            vertical-align: middle;
            width: 0.3rem;
            height: 0.3rem;
          }
        }
      }
    }
  }

  .noneCoupon {
    margin: 1.6rem auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 4.5rem;
      height: 4.5rem;
    }
    span {
      margin-top: 0.3rem;
      font-size: 0.36rem;
      color: #222222;
      @include font_color('title_color');
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;
    }
  }
}
.nomore {
  font-size: 0.24rem;
  color: #aeb5ca;
  text-align: center;
  margin: 0.4rem auto;
  @include font_color('invalid_coupon_nomore');
}
</style>
